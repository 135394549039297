import React from "react";

const Footer = () => {
  return (
    <div className="text-xs sm:text-xl mt-5 flex justify-center text-gray-500 font-ComicNeue">
      ©️ APEXIUM TECHNOLOGIES PRIVATE LIMITED
    </div>
  );
};

export default Footer;
